<template>
	<div class="xl:mt-12 xl:pt-10 overflow-y-auto">
    <div class="text-bold" v-if="isUploading" style="position: fixed; z-index: 9999999; top: 0; bottom: 0; right: 0; left: 0; display: flex; justify-content: center; align-items: center; text-align: center; transform: translateY(36px);">
      Uploading, {{ mediaProgress }}%
    </div>

    <div style="height: 60px; background-color: #F8F8F8; z-index: 999" class="fixed w-full top-0 left-0">
    </div>
		<!-- title name -->
		<label class="font-bold text-lg">{{$t('Event Page Design')}}</label>
		<vs-card class="relative vx-col card-event p-5 mt-2"  style="min-height: 600px">
			<!-- Page Title -->
			<div class="page-title">
				<label class="text-title">
					{{ $t('Event Name') }}
				</label>
					<vs-input v-filter-input
						class="w-full pb-6 mt-1"
						name="item-name"
						v-model="dataPageTitle"
						v-validate="'required'"
					/>
			</div>
			<!-- Description -->
			<div class="mb-4">
        <div class="flex justify-between mb-2">
          <label class="text-title ">
            {{ $t('Description') }}
          </label>
          <div>
            <div class="flex justify-between items-center">
              <div class="text-title">
                <div class="text-sub-title">
                  {{ $t('Remove font styling on Landing Page') }}
                </div>
              </div>
              <div class="ml-4">
                <vs-switch @click.stop="" color="success" v-model="dataRemoveFontStyle"/>
              </div>
            </div>
          </div>
        </div>
				<!-- <wysiwyg class="description" v-model="dataDescription" /> -->
        <ckeditor :editor="editor" v-model="dataDescription" :config="editorConfig"></ckeditor>
			</div>
			<!-- Embed Code -->
			<div class="">
        <div class="flex justify-between">
          <label class="text-title">
            {{ $t('Embed Code Live') }}
          </label>
          <div>
            <div class="text-title text-blue pointer text-bold" @click="$refs.updateVideoInput.click()">
              {{$t('Upload Video')}}
            </div>
            <input
              type="file"
              class="hidden"
              ref="updateVideoInput"
              @change="updateCurrVideo"
              accept="video/*"
              id="video-live"
            />
          </div>
        </div>
				<vs-textarea class="mt-1" rows="3" v-model="dataEmbedCode"/>
			</div>
      <div class="">
        <div class="flex justify-between">
          <label class="text-title ">
					{{ $t('Embed Code Pre Live') }} ({{ $t('Optional') }})
        </label>
          <div>
            <div class="text-title text-blue pointer text-bold" @click="$refs.updatePreVideoInput.click()">
              {{$t('Upload Video')}}
            </div>
            <input
              type="file"
              class="hidden"
              ref="updatePreVideoInput"
              @change="updateCurrPreVideo"
              accept="video/*"
              id="video-pre"
            />
          </div>
        </div>
				<vs-textarea class="mt-1" rows="3" v-model="dataEmbedCodePre"/>
			</div>

      <!-- Upload custom landing page -->
      <div class="text-title mt-16">
        <div class="flex justify-between mb-2">
          <label class="text-title ">
            {{ $t('Upload custom HTML as Landing Page') }}
          </label>
          <div>
            <div class="flex justify-between items-center">
              <div class="text-title">
                <div class="text-sub-title">
                  {{ $t('Enable Custom HTML') }}
                </div>
              </div>
              <div class="ml-4">
                <vs-switch :disabled="!dataEventHtmlName" @click.stop="" color="success" v-model="dataIsEnabledCustomHTML"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template>
        <div>
          <div class="flex align-items-center mt-2">
            <vs-button color="secondary" type="border" class="p-2 px-4 py-1" @click="$refs.updateInputHtmlZip.click()">
              <div class="flex items-center pt-1">
                <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
                {{$t('Upload File')}}
              </div>
            </vs-button>
            <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
            @click="removeHtml"></vs-button>
            <input type="file" class="hidden-visibility" ref="updateInputHtmlZip" @change="onSelectHtml" id="html-homepage" accept=".zip">
          </div>
          <div class="htmlZips mt-3 relative">
            <div class="flex align-items-center">
              <div class="flex items-center" v-if="dataEventHtmlName">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 mt-1">
                    <path d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6M20 18H16V16H14V18H4V8H14V10H16V8H20V18M16 12V10H18V12H16M14 12H16V14H14V12M18 16H16V14H18V16Z" />\
                  </svg>
                </div>
                <div class="ml-2 text-survey-option">{{ dataEventHtmlName }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Event image -->
      <div class="text-title mt-6 mb-2">{{$t('Event Image')}}</div>
      <template>
        <div class="flex">
          <!-- Image Container -->
          <div v-if="dataEventImage != null">
            <img :src="dataEventImage" alt="img" class="image-event-overview" />
          </div>
        </div>
        <!-- Image upload Buttons -->
        <div class="modify-img flex">
          <input type="file" class="hidden" ref="updateEventImgInput" @change="updateCurrEventImg"
            accept="image/*" data-attrib="image-feature-overview" />
          <vs-button color="secondary" type="border" class="p-1 px-4"
            @click="$refs.updateEventImgInput.click()">
            <div class="flex items-center pt-1">
              <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
              {{$t('Upload File')}}
            </div>
          </vs-button>
          <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
            @click="dataEventImage = null"></vs-button>
        </div>
      </template>

      <!-- event background -->
      <div class="text-title mt-8 mb-2">{{$t('Page Image Background')}}</div>
      <template>
        <div class="flex">
          <!-- Image Container -->
          <div v-if="dataBackground != null">
            <img :src="dataBackground" alt="img" class="image-event-overview" />
          </div>
        </div>
        <!-- Image upload Buttons -->
        <div class="modify-img flex">
          <input type="file" class="hidden" ref="updateImgBgInput" @change="updateCurrBgImg"
            accept="image/*" data-attrib="image-feature-overview" />
          <vs-button color="secondary" type="border" class="p-1 px-4"
            @click="$refs.updateImgBgInput.click()">
            <div class="flex items-center pt-1">
              <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
              {{$t('Upload File')}}
            </div>
          </vs-button>
          <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
            @click="dataBackground = null"></vs-button>
        </div>
      </template>

      <!-- Attachments (many)-->
			<div class="mt-8">
				<label class="text-title ">
					{{ $t('Attachment') }}
				</label>
			</div>
			<div class="">
				<div class="flex align-items-center mt-2">
					<vs-button color="secondary" type="border" class="p-2 px-4 py-1" @click="$refs.updateInput.click()">
						<div class="flex items-center pt-1">
							<svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
							{{$t('Upload File')}}
						</div>
					</vs-button>
					<input type="file" class="hidden-visibility" ref="updateInput" @change="onSelectAttachment" id="attachment-homepage">
				</div>
				<div class="attachments mt-3 relative">
					<div class="flex align-items-center
					" v-for="(attachment, index) in dataAttachments" :key="index">
						<div class="mb-3">
							<vs-icon @click="removeAttachment(index)" size="medium" class="icon-delete-options">cancel</vs-icon><div class="ml-12 text-survey-option">{{ attachment.name }}</div>
						</div>
					</div>
				</div>
			<div>

      <!-- coundown -->
      <!-- <div class="mb-4 mt-10 flex items-center">
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsEnabledCountdown" />
        </div>
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Event Countdown') }}</div>
        </div>
      </div> -->

      <div class="mb-4 mt-10 text-title">
        <label>{{ $t('Background type') }}</label>
        <vs-select
          name="language-settings-user"
          class="mb-10 mt-2"
          v-model="dataBackgroundType"
          >
          <vs-select-item
          v-for="item in backgroundTypes"
            :key="item.value"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </div>

      <!-- Bg style -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Enabled Box Background') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Add white block overlay for improved visibility') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsEnabledBoxBackground" :disabled="dataIsFullscreen"/>
        </div>
      </div>

      <!-- Dark Font -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Dark Font Color') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Default color is black - enable it to increase visibility on a dark background') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsEnabledDarkFont"/>
        </div>
      </div>

      <!-- only show registration form -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Only Show Registrartion Form') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Hide event information') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsRegistrationOnly" />
        </div>
      </div>

			<!-- interactive -->
      <!-- <div class="mb-4 mt-6 flex items-center">
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsShowInteractive" />
        </div>
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Interactive at Once') }}
          </div>
        </div>
      </div> -->

      <!-- Full screen -->
      <div class="mb-4 w-full flex justify-between mt-6 flex items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Maximum Browser Width') }}
          </div>
          <div class="text-sub-title">
            {{ $t('For bigger viewing on the video element') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsFullscreen" />
        </div>
      </div>

      <!-- Show Countdown -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Countdown') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Display countdown timer on the landing page') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsShowCountdown"/>
        </div>
      </div>

      <!-- Show Location -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Location') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Display event location on tlanding page') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsShowLocation"/>
        </div>
      </div>

      <!-- Show Event Date & Time -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Event Date & Time') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Display event time and date information') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsShowDateTime"/>
        </div>
      </div>

      <!-- Show T & C -->
      <div>
        <div class="mb-4 mt-6 flex justify-between items-center">
          <div class="text-title">
            <div class="text-title">
              {{ $t('Show Terms and Condition') }}
            </div>
            <div class="text-sub-title">
              {{ $t('Display terms and condition information') }}
            </div>
          </div>
          <div class="mr-4">
            <vs-switch @click.stop="" color="success" v-model="dataIsShowTC"/>
          </div>
        </div>
        <div class="mr-4" v-if="dataIsShowTC">
          <ckeditor :editor="editor" v-model="dataTC" :config="editorConfig"></ckeditor>
        </div>
      </div>

      <!-- Show Notes -->
      <div>
        <div class="mb-4 mt-6 flex justify-between items-center">
          <div class="text-title">
            <div class="text-title">
              {{ $t('Show Additional Notes') }}
            </div>
            <div class="text-sub-title">
              {{ $t('Display notes information') }}
            </div>
          </div>
          <div class="mr-4">
            <vs-switch @click.stop="" color="success" v-model="dataIsShowNotes"/>
          </div>
        </div>
        <div class="mr-4" v-if="dataIsShowNotes">
          <ckeditor :editor="editor" v-model="dataNotes" :config="editorConfig"></ckeditor>
        </div>
      </div>

      <!-- Show Notes placeholder -->
      <div>
        <div class="mb-4 mt-6 flex justify-between items-center">
          <div class="text-title">
            <div class="text-title">
              {{ $t('Notes Placeholder') }}
            </div>
            <div class="text-sub-title">
              {{ $t('Change placeholder in notes input') }}
            </div>
          </div>
          <div class="mr-4">
            <vs-switch @click.stop="" color="success" v-model="dataIsShowNotesPlaceholder"/>
          </div>
        </div>
        <div class="mr-4" v-if="dataIsShowNotesPlaceholder">
          <vs-textarea class="mt-1" rows="1" v-model="dataNotesPlaceholder"/>
        </div>
      </div>

      <!-- Show Event & Qustion Menu Dropdown -->
      <div class="mb-4 mt-6 flex justify-between items-center">
        <div class="text-title">
          <div class="text-title">
            {{ $t('Show Swicth & Question Menu') }}
          </div>
          <div class="text-sub-title">
            {{ $t('Show Switch & Question Menu on profile dropdown menu') }}
          </div>
        </div>
        <div class="mr-4">
          <vs-switch @click.stop="" color="success" v-model="dataIsShowSwitchQuestionMenu"/>
        </div>
      </div>

				<div class="flex justify-between">
					<vs-button color="#28C76F" class="save-btn-settings mt-10" @click="openPreview()">{{$t('Preview')}}</vs-button>
					<vs-button :class="{ 'is-loading': isSaving }" id="save-changes-homepage" @click="updateEvent()" class="save-btn-settings mt-10" >{{$t('Save Changes')}}</vs-button>
				</div>
			</div>
		</div>
		</vs-card>
	</div>
</template>

<script>
import $ from 'jquery';
import eventsApi from '@/api/event';
import fileApi from '@/api/file';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-swatches/dist/vue-swatches.min.css';

import CKEditor from '@ckeditor/ckeditor5-vue2';
import {
  Bold, ClassicEditor, Essentials, Italic, Paragraph, Table, Heading, Link, MediaEmbed, Image, List, Alignment, ImageUpload, LinkImage,
} from 'ckeditor5';
import 'ckeditor5/dist/ckeditor5.css';
import { getAxiosErrorMessage } from '../lib/helper';
// eslint-disable-next-line import/no-named-as-default
import { MyCustomUploadAdapterPlugin } from '../plugins/MyUploadAdapter';


export default {
  name: 'EventHomepage',
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'undo', 'redo', '|',
            'heading', '|',
            'bold', 'italic', '|',
            'link', 'imageUpload', 'insertTable', 'mediaEmbed', 'linkImage', '|',
            'bulletedList', 'numberedList', '|',
            'outdent', 'indent', '|',
            'blockQuote', 'alignment',
          ],
        },
        plugins: [
          Essentials, Paragraph, Bold, Italic, Heading, Link, Image,
          Table, MediaEmbed, List, Alignment, ImageUpload, LinkImage,
        ],
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        extraPlugins: [MyCustomUploadAdapterPlugin],
      },
      isFetching: false,
      eventData: null,
      dataPageTitle: '',
      dataDescription: '',
      dataEmbedCode: '',
      dataEmbedCodePre: null,
      dataAttachments: null,
      dataIsShowInteractive: false,
      dataIsRegistrationOnly: false,
      dataIsFullscreen: false,
      dataIsEnabledDarkFont: false,
      dataRemoveFontStyle: false,
      dataIsShowDateTime: true,
      dataIsShowTC: true,
      dataIsShowNotes: false,
      dataIsShowNotesPlaceholder: false,
      dataNotesPlaceholder: '',
      dataTC: '',
      dataNotes: '',
      dataIsShowSwitchQuestionMenu: true,
      dataIsShowLocation: true,
      dataIsShowCountdown: true,
      dataIsEnabledBoxBackground: false,
      dataBackground: null,
      dataEventImage: null,
      isSaving: false,
      isUploading: false,
      dataIsEnabledCountdown: false,
      dataBackgroundType: 'stretch',
      backgroundTypes: [
        { value: 'stretch', text: 'Stretch' },
        { value: 'repeat', text: 'Repeat' },
      ],
      mediaProgress: 0,
      cancelUpload: null,
      dataEventHtml: '',
      dataEventHtmlName: '',
      dataIsEnabledCustomHTML: false,
    };
  },
  watch: {
    dataIsFullscreen() {
      if (this.dataIsFullscreen) {
        this.dataIsEnabledBoxBackground = true;
      }
    },
    dataEventHtml() {
      if (!this.dataEventHtml) {
        this.dataIsEnabledCustomHTML = false;
      }
    },
  },
  mounted() {
    $(document).ready(() => {
      $('.editr--toolbar .dashboard label').click((event) => { $(event.target).focus(); });
    });
    if (this.dataIsFullscreen) {
      this.dataIsEnabledBoxBackground = true;
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    params() {
      const attachments = JSON.stringify(this.dataAttachments);
      const params = {
        id: this.dataId,
        name: this.dataPageTitle,
        description: this.dataDescription,
        embed_code: this.dataEmbedCode,
        embed_code_pre_event: this.dataEmbedCodePre,
        attachments,
        is_show_interactive_once: this.dataIsShowInteractive,
        background: this.dataBackground,
        custom_html_url: this.dataEventHtml,
      };
      return params;
    },
    linkCodeValue() {
      const linkCode = this.eventData && this.eventData.link_code_value ? this.eventData.link_code_value : '';
      return linkCode;
    },
    sidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/sidebar`;
      return linkCode;
    },
    withoutSidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/quest`;
      return linkCode;
    },
    highlightPresentationURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/highlight`;
      return linkCode;
    },
    eventQRURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/event`;
      return linkCode;
    },
    runnerControlURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/control`;
      return linkCode;
    },
    highlightGrafiasURL() {
      const linkCode = '';
      return linkCode;
    },
  },
  methods: {
    hideFileExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    fetchDetails() {
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        this.isFetching = false;
        this.setData();
        document.title = event.name;
        const eventSettings = JSON.parse(response.data.settings);
        this.setEventSettings(eventSettings);
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      eventsApi.getBySlug(this.slug, callback, errorCallback);
    },
    updateEvent() {
      this.isSaving = true;
      const notifTitle = this.$t('Event Settings');
      const params = this.params;
      const callback = () => {
        // const message = response.message;
        const message = this.$t('Your changes have been saved.');
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      const eventSettings = {
        question_answer: {
          is_enabled: this.dataIsAudienceAndQA,
          is_moderated: this.dataIsModeration,
          is_withdrawal: this.dataIsWithdrawal,
          is_replies: this.dataIsReplies,
          input_max_length: this.dataMaxLength,
          is_order_by_latest: this.dataIsDisplayLatestQuestion,
          is_closed: this.dataIsCloseQuestion,
          display_sort: this.dataDisplaySort,
          is_only_recent: this.dataIsShowOnlyRecent,
          limit: this.dataLimitQuestionLength,
          is_limit: this.dataIsLimitQuestion,
        },
        sidebar: {
          is_display_presentation_sidebar: this.dataIsDisplayPresentSidebar,
          is_qr_code: this.dataIsQRCode,
          is_display_question_pane: this.dataIsDisplayQuestionPane,
          is_registration_only: this.dataIsRegistrationOnly,
          is_registration_enabled: this.dataIsEnabledRegistrationForm,
          is_email_marketing: this.dataIsEnabledEmailMarketing,
          is_email_update: this.dataIsEnabledEmailUpdate,
          is_enable_extended_field: this.dataIsEnabledExtendedForm,
        },
        polls: {
          is_enabled: this.dataIsLivePolls,
          is_vote_counter_visible: this.dataIsVoteCounter,
          is_show_result: this.dataIsPollResult,
        },
        style: {
          is_remove_font_style: this.dataRemoveFontStyle,
          is_dark_color_event: this.dataIsEnabledDarkFont,
          is_enabled_box_bg: this.dataIsEnabledBoxBackground,
          is_fullscreen: this.dataIsFullscreen,
          font_size: this.dataFontSize || 28,
          is_enabled_countdown: this.dataIsEnabledCountdown,
          is_hide_participant_name: this.dataIsHideParticipantName,
          is_dark_color: this.dataIsDarkColor,
          display_font: this.dataDisplayFont,
          background: this.dataBackground,
          background_type: this.dataBackgroundType,
          event_image: this.dataEventImage,
          is_show_countdown: this.dataIsShowCountdown,
          is_show_location: this.dataIsShowLocation,
          is_show_date_time: this.dataIsShowDateTime,
          is_show_tc: this.dataIsShowTC,
          is_show_notes: this.dataIsShowNotes,
          is_show_notes_placeholder: this.dataIsShowNotesPlaceholder,
          notes_placeholder: this.dataNotesPlaceholder,
          tc: this.dataTC,
          note: this.dataNotes,
          is_show_switch_question: this.dataIsShowSwitchQuestionMenu,
        },
        presentation: {
          question_with_sidebar: this.dataQuestionWithSidebar,
          question_without_sidebar: this.dataQuestionWithoutSidebar,
          highlighted_question: this.dataHighlightedPresentQuestion,
          event_qr: this.dataEventQR,
          display_runner_control: this.dataDisplayRunnerControl,
          is_enabled_custom_html: this.dataIsEnabledCustomHTML,
        },
        grafias: {
          highlighted_question: this.dataHighlightedGrafiasQuestion,
          is_switch: this.dataHighlightGrafiasSwitch,
        },
        email: {
          sender_name: this.dataSenderName,
          sender_mail: null,
          footer_name: this.dataSenderName,
        },
      };
      params.settings = JSON.stringify(eventSettings);
      eventsApi.update(this.eventData.id, params, callback, errorCallback);
    },
    updateCurrBgImg(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataBackground = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataBackground = url;
          this.isUploading = false;
          this.$vs.loading.close();
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    updateCurrEventImg(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataEventImage = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataEventImage = url;
          this.isUploading = false;
          this.$vs.loading.close();
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    onSelectAttachment(event) {
      this.$vs.loading();
      const files = event.target.files;

      if (files.length > 0) {
        const file = files[0];

        // Generate Params
        const uploadParams = new FormData();
        uploadParams.append('file', file);
        uploadParams.append('is_resize', 0);

        const callback = (response) => {
          const fileUrl = response.data;
          // const fileName = this.file.name;
          const attachment = {
            name: file.name,
            file_path: fileUrl,
          };
          this.dataAttachments.push(attachment);
          this.$vs.loading.close();
        };
        const errorCallback = (e) => {
          // eslint-disable-next-line
					console.log(e);
          this.$vs.loading.close();
        };
        const progresCallback = () => {
        };
        // Upload
        fileApi.upload(uploadParams, callback, errorCallback, progresCallback);
      }
    },
    removeAttachment(index) {
      this.dataAttachments.splice(index, 1);
    },
    onSelectHtml(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataEventHtml = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataEventHtml = url;
          this.dataEventHtmlName = file.name;
          this.isUploading = false;
          this.$vs.loading.close();
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload zip'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    removeHtml() {
      this.dataEventHtmlName = '';
      this.dataEventHtml = null;
    },
    setData() {
      this.dataPageTitle = this.eventData.name;
      this.dataAttachments = this.eventData.attachments;
      this.dataDescription = this.eventData.description;
      this.dataEmbedCode = this.eventData.embed_code;
      this.dataEmbedCodePre = this.eventData.embed_code_pre_event;
      this.dataIsShowInteractive = this.eventData.is_show_interactive_once;
      this.dataEventHtml = this.eventData.custom_html_url;
      this.dataEventHtmlName = this.eventData.custom_html_url;
      this.dataBackground = this.eventData.background;
      this.dataEventImage = this.eventData.event_image;
    },
    setEventSettings(eventSettings) {
      if (eventSettings) {
        // audience qna
        this.dataIsAudienceAndQA = eventSettings.question_answer && eventSettings.question_answer
          .is_enabled ? eventSettings.question_answer.is_enabled : false;
        this.dataIsModeration = eventSettings.question_answer && eventSettings.question_answer
          .is_moderated ? eventSettings.question_answer.is_moderated : false;
        this.dataIsWithdrawal = eventSettings.question_answer && eventSettings.question_answer
          .is_withdrawal ? eventSettings.question_answer.is_withdrawal : false;
        this.dataIsReplies = eventSettings.question_answer && eventSettings.question_answer
          .is_replies ? eventSettings.question_answer.is_replies : false;
        this.dataMaxLength = eventSettings.question_answer && eventSettings.question_answer
          .input_max_length ? eventSettings.question_answer.input_max_length : false;
        this.dataIsDisplayLatestQuestion = eventSettings.question_answer && eventSettings
          .question_answer.is_order_by_latest ? eventSettings.question_answer.is_order_by_latest
          : false;
        this.dataIsCloseQuestion = eventSettings.question_answer && eventSettings.question_answer
          .is_closed ? eventSettings.question_answer.is_closed : false;
        this.dataDisplaySort = eventSettings.question_answer && eventSettings.question_answer.display_sort ? eventSettings.question_answer.display_sort : 'recent';
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsLimitQuestion = eventSettings.question_answer && eventSettings.question_answer.is_limit ? eventSettings.question_answer.is_limit : false;
        this.dataLimitQuestionLength = eventSettings.question_answer && eventSettings.question_answer.limit ? eventSettings.question_answer.limit : 0;
        // sedebar
        this.dataIsQRCode = eventSettings.sidebar && eventSettings.sidebar.is_qr_code
          ? eventSettings.sidebar.is_qr_code : false;
        this.dataIsDisplayPresentSidebar = eventSettings.sidebar && eventSettings.sidebar
          .is_display_presentation_sidebar ? eventSettings.sidebar
            .is_display_presentation_sidebar : false;
        this.dataIsDisplayQuestionPane = eventSettings.sidebar && eventSettings.sidebar
          .is_display_question_pane ? eventSettings.sidebar
            .is_display_question_pane : false;
        this.dataIsRegistrationOnly = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_only ? eventSettings.sidebar
            .is_registration_only : false;
        this.dataIsEnabledRegistrationForm = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_enabled ? eventSettings.sidebar
            .is_registration_enabled : true;
        this.dataIsEnabledEmailUpdate = eventSettings.sidebar && eventSettings.sidebar
          .is_email_update ? eventSettings.sidebar
            .is_email_update : false;
        this.dataIsEnabledEmailMarketing = eventSettings.sidebar && eventSettings.sidebar
          .is_email_marketing ? eventSettings.sidebar
            .is_email_marketing : false;
        this.dataIsEnabledExtendedForm = eventSettings.sidebar && eventSettings.sidebar
          .is_enable_extended_field ? eventSettings.sidebar
            .is_enable_extended_field : false;

        // polls
        this.dataIsLivePolls = eventSettings.polls && eventSettings.polls.is_enabled
          ? eventSettings.polls.is_enabled : false;
        this.dataIsVoteCounter = eventSettings.polls && eventSettings.polls
          .is_vote_counter_visible ? eventSettings.polls.is_vote_counter_visible : false;
        this.dataIsPollResult = eventSettings.polls && eventSettings.polls.is_show_result
          ? eventSettings.polls.is_show_result : false;

        // background
        this.dataIsEnabledBoxBackground = eventSettings.style && eventSettings.style.is_enabled_box_bg ? eventSettings.style.is_enabled_box_bg	 : false;
        this.dataIsFullscreen = eventSettings.style && eventSettings.style.is_fullscreen ? eventSettings.style.is_fullscreen	 : false;
        this.dataRemoveFontStyle = eventSettings.style && eventSettings.style.is_remove_font_style ? eventSettings.style.is_remove_font_style	 : false;
        this.dataIsEnabledDarkFont = eventSettings.style && eventSettings.style.is_dark_color_event ? eventSettings.style.is_dark_color_event	 : false;
        this.dataBackgroundType = eventSettings.style && eventSettings.style.background_type ? eventSettings.style.background_type : 'stretch';
        this.dataFontSize = eventSettings.style && eventSettings.style.font_size ? eventSettings.style.font_size : 28;
        this.dataIsEnabledCountdown = eventSettings.style && eventSettings.style.is_enabled_countdown ? eventSettings.style.is_enabled_countdown	 : false;
        this.dataIsHideParticipantName = eventSettings.style && eventSettings.style.is_hide_participant_name ? eventSettings.style.is_hide_participant_name	 : false;
        this.dataIsDarkColor = eventSettings.style && eventSettings.style.is_dark_color ? eventSettings.style.is_dark_color	 : false;
        this.dataDisplayFont = eventSettings.style && eventSettings.style.display_font ? eventSettings.style.display_font	 : 'Roboto';
        this.dataBackground = eventSettings.style && eventSettings.style.background ? eventSettings.style.background	 : null;
        this.dataEventImage = eventSettings.style && eventSettings.style.event_image ? eventSettings.style.event_image	 : null;
        this.dataTC = eventSettings.style && eventSettings.style.tc ? eventSettings.style.tc	: '';
        this.dataIsShowNotes = eventSettings.style && eventSettings.style.is_show_notes ? eventSettings.style.is_show_notes	 : false;
        this.dataNotes = eventSettings.style && eventSettings.style.note ? eventSettings.style.note	 : '';
        this.dataIsShowNotesPlaceholder = eventSettings.style && eventSettings.style.is_show_notes_placeholder ? eventSettings.style.is_show_notes_placeholder	 : false;
        this.dataNotesPlaceholder = eventSettings.style && eventSettings.style.notes_placeholder ? eventSettings.style.notes_placeholder	 : '';

        if (eventSettings && !eventSettings.style) {
          this.dataIsShowCountdown = true;
          this.dataIsShowLocation = true;
          this.dataIsShowDateTime = true;
          this.dataIsShowTC = true;
          this.dataIsShowSwitchQuestionMenu = true;
        } else {
          this.dataIsShowDateTime = eventSettings.style && eventSettings.style.is_show_date_time ? eventSettings.style.is_show_date_time	 : false;
          this.dataIsShowLocation = eventSettings.style && eventSettings.style.is_show_location ? eventSettings.style.is_show_location	 : false;
          this.dataIsShowCountdown = eventSettings.style && eventSettings.style.is_show_countdown ? eventSettings.style.is_show_countdown	 : false;
          this.dataIsShowTC = eventSettings.style && eventSettings.style.is_show_tc ? eventSettings.style.is_show_tc	 : false;
          this.dataIsShowSwitchQuestionMenu = eventSettings.style && eventSettings.style.is_show_switch_question ? eventSettings.style.is_show_switch_question	 : false;
        }

        // presentation & grafias
        this.dataHighlightedGrafiasQuestion = eventSettings.grafias && eventSettings.grafias.highlighted_question ? eventSettings.grafias.highlighted_question : this.highlightGrafiasURL;
        this.dataDisplayRunnerControl = eventSettings.presentation && eventSettings.presentation.display_runner_control ? eventSettings.presentation.display_runner_control	 : this.runnerControlURL;
        this.dataEventQR = eventSettings.presentation && eventSettings.presentation.event_qr ? eventSettings.presentation.event_qr	 : this.eventQRURL;
        this.dataHighlightedPresentQuestion = eventSettings.presentation && eventSettings.presentation.highlighted_question ? eventSettings.presentation.highlighted_question	 : this.highlightPresentationURL;
        this.dataQuestionWithoutSidebar = eventSettings.presentation && eventSettings.presentation.question_without_sidebar ? eventSettings.presentation.question_without_sidebar	 : this.withoutSidebarURL;
        this.dataQuestionWithSidebar = eventSettings.presentation && eventSettings.presentation.question_with_sidebar ? eventSettings.presentation.question_with_sidebar	 : this.sidebarURL;
        this.dataIsEnabledCustomHTML = eventSettings.presentation && eventSettings.presentation.is_enabled_custom_html ? eventSettings.presentation.is_enabled_custom_html	 : false;
        this.dataHighlightGrafiasSwitch = eventSettings.grafias && eventSettings.grafias.is_switch ? eventSettings.grafias.is_switch : this.highlighSwitch;

        // mail
        this.dataSenderName = eventSettings.email && eventSettings.email.sender_name ? eventSettings.email.sender_name : '';

        // must be in the most bottom
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_countdown')) this.dataIsShowCountdown = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_location')) this.dataIsShowLocation = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_date_time')) this.dataIsShowDateTime = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_tc')) this.dataIsShowTC = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_switch_question')) this.dataIsShowSwitchQuestionMenu = true;
      }
    },
    openPreview() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.linkCodeValue,
        },
      });
      window.open(`${routeData.href}`, '_blank');
    },
    updateCurrVideo(input) {
      const oneMB = 1048576; // MB
      const uploadLimit = 200;
      this.$vs.loading({
        type: 'default',
      });
      if (input.target.files && input.target.files[0]) {
        if (input.target.files && input.target.files[0]) {
          const fileSizeInMb = input.target.files[0].size / oneMB;
          if (fileSizeInMb <= uploadLimit) {
            this.isUploading = true;
            const file = input.target.files[0];
            // Upload Image
            const formData = new FormData();
            formData.append('file', file);

            const callback = (response) => {
              const url = response.data;
              this.dataEmbedCode = url;
              this.isUploading = false;
              this.mediaProgress = 0;
              this.$vs.loading.close();
            };

            const errorCallback = (e) => {
              const message = getAxiosErrorMessage(e);
              this.$vs.notify({
                title: this.$t('Upload Video'),
                text: message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
              });
              this.mediaProgress = 0;
              this.isUploading = false;
              this.$vs.loading.close();
            };

            const progressCallback = (progress) => {
              this.mediaProgress = progress;
            };

            const cancelUploadCallback = (c) => {
              this.cancelUpload = c;
            };

            fileApi.upload(formData, callback, errorCallback, progressCallback, cancelUploadCallback);
          } else {
            setTimeout(() => {
              this.$vs.loading.close();
            }, 500);
            this.$vs.notify({
              title: this.$t('Upload Video'),
              text: 'Sorry, currently we cant upload the file, the maximum file size is 200 MB',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              time: '10000',
            });
          }
        }
      }
    },
    updateCurrPreVideo(input) {
      const oneMB = 1048576; // MB
      const uploadLimit = 200;
      this.$vs.loading({
        type: 'default',
      });
      if (input.target.files && input.target.files[0]) {
        const fileSizeInMb = input.target.files[0].size / oneMB;
        if (fileSizeInMb <= uploadLimit) {
          this.isUploading = true;
          const file = input.target.files[0];
          // Upload Image
          const formData = new FormData();
          formData.append('file', file);

          const callback = (response) => {
            const url = response.data;
            this.dataEmbedCodePre = url;
            this.isUploading = false;
            this.mediaProgress = 0;
            this.$vs.loading.close();
          };

          const errorCallback = (e) => {
            const message = getAxiosErrorMessage(e);
            this.$vs.notify({
              title: this.$t('Upload Video'),
              text: message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            });
            this.isUploading = false;
            this.mediaProgress = 0;
            this.$vs.loading.close();
          };

          const progressCallback = (progress) => {
            this.mediaProgress = progress;
          };

          const cancelUploadCallback = (c) => {
            this.cancelUpload = c;
          };

          fileApi.upload(formData, callback, errorCallback, progressCallback, cancelUploadCallback);
        } else {
          setTimeout(() => {
            this.$vs.loading.close();
          }, 500);
          this.$vs.notify({
            title: this.$t('Upload Video'),
            text: 'Sorry, currently we cant upload the file, the maximum file size is 200 MB',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            time: '10000',
          });
        }
      }
    },
    onCancel() {
      if (this.cancelUpload) {
        this.cancelUpload();
        this.isUploading = false;
      }
    },
  },
  created() {
    this.fetchDetails();
  },
};
</script>

<style lang="scss">
.avatar-col {
	width: 12rem;
}
.card-event {
	width: 100%;
}
@media screen and (min-width: 800px) {
	.card-event {
		width: 720px;
	}
}
@media screen and (min-width: 1201px) and (max-width: 1211px),
	only screen and (min-width: 636px) and (max-width: 991px) {
	#account-info-col-1 {
		width: calc(100% - 12rem) !important;
	}
}
.input-code .vs-con-input .vs-inputx {
	height: 50px!important;
	font-size:  22px!important;
	background-color: #ECECEC;
	border: 0px!important;
	color: black;
}
.date-event{
	width: 100%;
}
.icon-calendar-event{
	color: grey;
	position: absolute;
	top: 0;
	margin: 0.3em;
	right: 0;
}
.attachments-btn{
	border-radius: 100%!important;
}
.text-survey-option{
	padding-top: 5px;
	font-size: 15px;
}
.icon-delete-options{
	color:grey!important;
	position: absolute;
	cursor: pointer;
}
.container-option-survey{
	max-height: 200px;
	height: 150px;
	overflow-y: auto;
}
.editor-homepage .ql-editor{
	min-height: 400px!important;
}
.swatches-color .vue-swatches__trigger{
	width: 30px!important;
    height: 30px!important;
}
.ck-editor__editable_inline {
  border-radius: 8px; /* Adjust the border radius as needed */
  font-size: 16px; /* Adjust the font size as needed */
  padding: 10px; /* Optional: add some padding for better appearance */
  border: 1px solid #ccc; /* Optional: add a border to see the border radius more clearly */
  max-height: 600px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Add vertical scroll */
}

.ck-rounded-corners .ck-editor__main > .ck-editor__editable {
  border-bottom-left-radius: 8px!important;
  border-bottom-right-radius: 8px!important;
}

.ck.ck-sticky-panel__content {
  background-color: #f0f0f0 !important; /* Example: Change background color */
  border-top-left-radius: 8px!important;
  border-top-right-radius: 8px!important;
  padding: 1px !important; /* Example: Adjust padding */
}


</style>
